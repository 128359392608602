<template>
  <div class="activity-page">
    <headers />
    <div class="invite-content">
      <div class="invite-box">
        <div class="activity-content">
          <div class="invite-title">活动内容</div>
          <div class="invite-rules">
            1.
            即日起，每成功邀请一位亚马逊卖家注册并使用暗度，将获得5个金币，单个人最高可以获得50个金币。
          </div>
          <div class="invite-rules">
            2.
            邀请非亚马逊卖家恶意骗取奖励将视为违规，暗度有权取消对应账号的活动资格或封号。
          </div>
          <div class="invite-rules">
            <!-- 
            3. 活动有效期：2022年7月1日---2022年12月25日 
            -->
            3. 活动有效期：2023年3月1号—2023年12月31号
          </div>
          <!-- <div class="invite-rules">
            1.
            即日起，每成功邀请一位亚马逊卖家注册并使用暗度，将获得6次免费查询（相当于30个金币）的机会。
          </div>
          <div class="invite-rules">
            2.
            邀请非亚马逊卖家恶意骗取奖励将视为违规，暗度有权取消对应账号的活动资格或封号。
          </div> -->
        </div>
        <div class="invite-method">
          <div class="invite-title">邀请方法</div>
          <div class="invite-rules">
            1. 使用二维码邀请：<span>右键复制（鼠标右键 -> 复制图片）</span
            >下方专属邀请二维码，使用微信转发给您的同事或朋友。
          </div>
          <div class="invite-rules">
            二维码有效期至&nbsp;<span>{{ ticket.expirationDate }}</span
            >。
          </div>
          <div class="qrcode-box">
            <div class="qrcode" ref="qrCodeUrl" v-if="qrCodeUrl"></div>
          </div>
          <div class="invite-rules">
            2. 使用链接邀请：
            <span class="link-url" ref="link">{{ copyVal }}</span>
            <span class="tap-clone" @click="tapCopyLink">复制链接</span>
          </div>
        </div>
      </div>
      <div class="right-copy">本活动最终解释权归暗度所有</div>
    </div>
    <right-icon />
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { getCustomerTicket } from "@/api/activity.js";
import Headers from "@/components/Header/index.vue";
import RightIcon from "@/components/right-icon/index.vue";
export default {
  name: "inviteActivity",
  components: {
    Headers,
    RightIcon,
  },
  props: {},
  data() {
    return {
      ticket: {},
      qrCodeUrl: "",
      tableData: [],
      copyVal: "",
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getCustomerTicket();
  },
  methods: {
    creatQrCode() {
      this.$nextTick(() => {
        this.$refs.qrCodeUrl.innerHTML = "";
        new QRCode(this.$refs.qrCodeUrl, {
          text: this.qrCodeUrl, // 需要转换为二维码的内容
          width: 220,
          height: 220,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
    tapCopyLink() {
      window.getSelection().selectAllChildren(this.$refs.link);
      document.execCommand("copy");
      this.$message.success("复制成功");
    },
    getCustomerTicket() {
      getCustomerTicket().then((res) => {
        if (res.success) {
          this.ticket = res.data;
          this.copyVal =
            process.env.NODE_ENV === "production"
              ? `https://${location.host}/home?zbuc=${res.data.customerCode}`
              : `http://${location.host}/home?zbuc=${res.data.customerCode}`;
          this.qrCodeUrl = res.data.url;
          this.creatQrCode();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.activity-page {
  padding-top: 80px;
}
.invite-content {
  display: flex;
  justify-content: center;
  position: relative;

  .invite-box {
    min-width: 1200px;
    background: url("../../assets/activity/invite-activity-bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 300px 0 120px 210px;
    color: #373e4f;
    .invite-title {
      width: 780px;
      font-size: 30px;
      text-align: center;
      margin-bottom: 28px;
    }
    .invite-rules {
      font-size: 16px;
      line-height: 32px;
      span {
        color: #ffa200;
      }
      .link-url {
        color: #373e4f;
      }
    }
    .activity-content {
      margin-bottom: 50px;
    }
    .invite-method {
      .qrcode-box {
        display: flex;
        justify-content: center;
        width: 780px;
        .qrcode {
          border: 1px solid #7443ff;
          padding: 5px;
          margin: 30px auto;
        }
      }
      .tap-clone {
        margin-left: 8px;
        cursor: pointer;
        color: #0063fa;
      }
    }
  }

  .right-copy {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 22px;
    text-align: center;
    font-size: 16px;
    color: #fff;
  }
}
</style>
